import { apiCall, setTokenHeader } from "../../services/api";
import { SET_CURRENT_USER } from "../actionTypes";
import { addError, removeError } from "./errors";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logout() {
  return (dispatch) => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}

export function authUser(type, userData) {
  return (dispatch) => {
    // wrap our thunk in a promise so we can wait for the API call
    return new Promise(async (resolve, reject) => {
      try {
        let response = await apiCall("post", `/api/auth/${type}`, userData);
        let { token, validExistingSession, verifyTFA, isCurrentSessionValid, ...otherFields } = response;
        if (isCurrentSessionValid) {
          resolve({ isCurrentSessionValid, ...otherFields });
        } else {
          localStorage.setItem("jwtToken", token);
          setAuthorizationToken(token);
          dispatch(removeError());
          if (validExistingSession || verifyTFA) {
            resolve({ validExistingSession, verifyTFA, ...otherFields });
          } else {
            dispatch(setCurrentUser(otherFields));
            resolve({ validExistingSession: false, verifyTFA: false, ...otherFields });
          }
        }
      } catch (err) {
        console.error("[AuthUser] error inside auth call", err);
        dispatch(addError(err.message || err));
        reject(err); // indicate the API call failed
      }
    });
  };
}
