import React from "react";
import { Provider } from "react-redux";
import store from "../store/index";
import { BrowserRouter as Router } from "react-router-dom";
import { setAuthorizationToken, setCurrentUser } from "../store/actions/auth";
import jwtDecode from "jwt-decode";
import { CurrentUserContext } from "./CurrentUserContext";
import { Box } from "@mui/material";
import SideDrawer from "./SideDrawer";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SuccessMessageProvider } from "../components/reports/SuccessMessageProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppEnvProvider } from "../config/AppEnvProvider";
import Main from "./Main";
import { SnackbarProvider } from "../components/inputFields/SnackBarProvider";

let currentUser = {};

const theme = createTheme({
  typography: {
    fontSize: 13, // Default font size is 14px
  },
});

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
  try {
    currentUser = jwtDecode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(currentUser));
  } catch (e) {
    localStorage.removeItem("jwtToken");
    store.dispatch(setCurrentUser({}));
  }
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CurrentUserContext.Provider value={currentUser}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppEnvProvider>
            <QueryClientProvider client={new QueryClient()}>
              <Provider store={store}>
                <SuccessMessageProvider>
                  <SnackbarProvider>
                    <Router>
                      <div className="onboarding">
                        <Box sx={{ display: "flex" }}>
                          <SideDrawer />
                          <Main />
                        </Box>
                      </div>
                    </Router>
                  </SnackbarProvider>
                </SuccessMessageProvider>
              </Provider>
            </QueryClientProvider>
          </AppEnvProvider>
        </LocalizationProvider>
      </CurrentUserContext.Provider>
    </ThemeProvider>
  );
};

export default App;
