import React, { createContext, useContext, useReducer } from "react";
import CustomSnackbar from "./CustomSnackBar";

const SnackbarContext = createContext(null);

export const useSnackBar = () => {
  return useContext(SnackbarContext);
};

const snackBarReducer = (state, action) => {
  switch (action.type) {
    case "set":
      return { ...state, message: action.message, severity: action.severity };
    case "clear":
      return { ...state, message: "" };
    default:
      throw new Error("Unknown action type");
  }
};

export const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(snackBarReducer, { message: "", severity: "" });
  function handleSnackBarClose(e, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "clear" });
  }

  return (
    <SnackbarContext.Provider value={{ state, dispatch }}>
      {children}
      <CustomSnackbar
        open={!!state.message}
        autoHideDuration={4000}
        onClose={handleSnackBarClose}
        message={state.message}
        severity={state.severity}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </SnackbarContext.Provider>
  );
};
