import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  getColumnsForRecordType,
  getDetailRecordType,
  getExcelColumnsForRecordType,
  getSearchColumnsForRecordType,
  reportEntities,
} from "../../services/standardEntityFieldService";
import { getDefaultValuesForFields } from "../../services/fieldsUtils";
import { isTransactionEntity, hasDetailEntity } from "../../services/standardEntityFieldService";
import { debounce } from "@mui/material/utils";
import _ from "lodash";
import Stack from "@mui/material/Stack";
import { apiCall } from "../../services/api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RecordTable from "./RecordTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import RefreshIcon from "@mui/icons-material/Refresh";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import withAuth from "../../hocs/withAuth";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Switch } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "../../config/dayjsConfig";
import ExcelReport from "../buttons/ExcelReportGenerator";
import { createExcelReportFromRecords } from "../../services/reportGenerationUtils";
import { getPageTitle } from "../../services/standardEntityFieldService";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Filter } from "../../types/filter";
import { isEmptyObj } from "../../services/utils";
import CircularProgress from "@mui/material/CircularProgress";
import { getOpeningStockDate } from "../../services/dateUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let recordIdToRecordsMap = {};
let searchFieldNameVsFieldMap = {};

function RecordTabularView({
  columns = [],
  searchFields = [],
  creationAllowed = true,
  entityType,
  pageTitle,
  setSelectedRecord = (selectedRecord) => {},
  recordSearchRequest,
  setRecordSeachRequest = (prevSearchRequest) => {},
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
}) {
  let { recordType }: any = useParams();
  recordType = entityType ? entityType : recordType;
  if (_.isEmpty(searchFields)) {
    searchFields = getSearchColumnsForRecordType(recordType);
  }
  creationAllowed = creationAllowed && !reportEntities.includes(recordType);
  if (_.isEmpty(pageTitle)) {
    pageTitle = getPageTitle(recordType);
  }
  if (_.isEmpty(columns)) {
    columns = getColumnsForRecordType(recordType);
  }
  let defaultValues = getDefaultValuesForFields(searchFields);

  const [isPageChangeDisabled, setIsPageChangeDisabled] = useState(false);
  const [recordTotalCount, setRecordTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [loading, setLoading] = useState(false);

  const populateSearchFieldsNameToFieldMap = (searchFields) => {
    if (_.isEmpty(searchFields)) {
      return;
    }
    searchFields.forEach((searchField) => {
      searchFieldNameVsFieldMap[searchField.name] = searchField;
    });
  };

  const handleChangePage = (event, newPage) => {
    if (!isTransactionEntity(recordType) || !isPageChangeDisabled) {
      setIsPageChangeDisabled(true);
      setPageNo(newPage);
      fetchRecordsForFilters({ page: { page: newPage, size: rowsPerPage } });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageNo(0);
    fetchRecordsForFilters({ page: { page: 0, size: +event.target.value } });
  };

  useEffect(() => {
    setError(null);
    fetchRecordsForFilters({
      page: { page: 0, size: rowsPerPage },
      searchRequest: recordSearchRequest,
      searchQuery: "",
    });
    setPageNo(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordType, recordSearchRequest, rowsPerPage, startDate, endDate]);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        setLoading(true);
        apiCall("post", `/api/entity/${recordType}/search`, request)
          .then((response) => {
            if (response == null) {
              console.warn("[RecordTabularView] Null response for records fetch");
              return;
            }
            callback(response);
          })
          .catch((err) => {
            console.error("[RecordTabularView] Error fetching records", err);
          })
          .finally(() => {
            setLoading(false);
          });
      }, 400),
    [recordType]
  );

  const handlePicklistValueChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    let newSelectedCategories = typeof value === "string" ? value.split(",") : Array.isArray(value) ? value : [value];
    setRecordSeachRequest((prevState) => {
      prevState[event.target.name] = newSelectedCategories;
      return { ...prevState };
    });
    setError(null);
    fetchRecordsForFilters({});
  };

  const toInputUppercase = (e, field) => {
    if (!field || field.type !== "number" || !field.decimal) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  const getExcelReportData = async (
    page = { page: 0, size: recordTotalCount },
    searchRequest = recordSearchRequest,
    searchQuery = query,
    timeFilter = { startDate, endDate }
  ) => {
    try {
      let request = {
        page,
        filter: getRecordsSearchRequestFilters(searchRequest),
        query: searchQuery,
        timeFilter,
      };
      const response = await apiCall("post", `/api/entity/${recordType}/search`, request);
      let { results } = response;
      let excelColumns = getExcelColumnsForRecordType(recordType);
      return results ? createExcelReportFromRecords(results, excelColumns) : [];
    } catch (error) {
      console.error("Error fetching records:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const getExcelReportDataDtl = async (
    page = { page: 0, size: -1 },
    searchRequest = recordSearchRequest,
    searchQuery = query,
    timeFilter = { startDate, endDate }
  ) => {
    let detailRecordType = getDetailRecordType(recordType);
    try {
      let request = {
        page,
        foreignFilters: {
          [recordType]: getRecordsSearchRequestFilters(searchRequest, detailRecordType),
        },
        query: searchQuery,
        timeFilter,
      };
      const response: any = await apiCall("post", `/api/entity/${detailRecordType}/search`, request);
      let { results } = response;
      let excelColumns = getExcelColumnsForRecordType(detailRecordType);
      return results ? createExcelReportFromRecords(results, excelColumns) : [];
    } catch (error) {
      console.error("Error fetching records:", error);
      throw error; // Re-throw the error for the caller to handle
    }
  };

  const handleClearInput = () => {
    setRecordSeachRequest(defaultValues);
    setError(null);
    setRecordTotalCount(0);
    setPageNo(0);
    setQuery("");
    fetchRecordsForFilters({
      page: { page: 0, size: rowsPerPage },
      searchRequest: defaultValues,
      searchQuery: "",
    });
  };

  const handleRecordSearchRequestChange = (e) => {
    let newSearchRequest = { ...recordSearchRequest };
    newSearchRequest[e.target.name] = e.target.value;
    setRecordSeachRequest(newSearchRequest);
    setError(null);
    setPageNo(0);
    fetchRecordsForFilters({
      page: { page: 0, size: rowsPerPage },
      searchRequest: newSearchRequest,
    });
  };

  const handleRecordSelection = async (recordId) => {
    let selectedRecord = recordIdToRecordsMap[recordId];
    if (!reportEntities.includes(recordType)) {
      setSelectedRecord(selectedRecord);
    }
  };

  function fetchRecordsForFilters({
    page = { page: pageNo, size: rowsPerPage },
    searchRequest = recordSearchRequest,
    searchQuery = query,
    timeFilter = { startDate, endDate },
    idSortOrder = sortOrder,
  }) {
    let foreignFilters: Map<String, Filter> = getRecordsSearchRequestForeignFilters(searchRequest);
    //convert it to request friendly form
    if (foreignFilters instanceof Map) {
      foreignFilters = Object.fromEntries(foreignFilters);
    }

    fetch(
      {
        page,
        filter: getRecordsSearchRequestFilters(searchRequest),
        query: searchQuery,
        timeFilter,
        foreignFilters,
        returnTotalCount: !isTransactionEntity(recordType) && !reportEntities.includes(recordType),
        sortOrders: [["id", idSortOrder]],
      },
      (response) => {
        if (response == null) {
          console.warn("[RecordTabularView] Null response for records fetch");
          return;
        }
        setFilteredRecords(response.results);
        setRecordTotalCount(response.totalCount);
        addRecordsToMap(response.results);
        setIsPageChangeDisabled(false);
      }
    );
  }

  function addRecordsToMap(newRecords) {
    if (!_.isEmpty(newRecords)) {
      recordIdToRecordsMap = {};
      newRecords.forEach((record) => {
        recordIdToRecordsMap[record.id] = record;
      });
    }
  }

  const getRecordsSearchRequestFilters = (searchRequest, detailRecordType?) => {
    let filters = [];

    for (let key in searchRequest) {
      if (typeof searchFieldNameVsFieldMap[key] == "undefined" || searchRequest[key] == null || searchRequest[key] === "") {
        continue;
      }
      if (searchFieldNameVsFieldMap[key].fEntityType) {
        //handled by separate function, to clean up and combine
        continue;
      }
      if (searchFieldNameVsFieldMap[key].type === "text") {
        filters.push({
          filters: [],
          field: key,
          values: [searchRequest[key]],
          type: "CONTAINS",
        });
      } else if (searchFieldNameVsFieldMap[key].type === "number") {
        filters.push({
          filters: [],
          field: key,
          values: [searchRequest[key]],
          type: "EQUALS",
        });
      } else {
        // let values = searchRequest[key] && searchRequest[key].length >0? searchRequest[key].map((value) => value.toLowerCase()): [];
        filters.push({
          filters: [],
          field: key,
          values: [searchRequest[key]],
          type: "IN",
        });
      }
    }
    filters.push(getEntitySpecificFilters(detailRecordType));
    return { filters, field: null, values: [], type: "AND" };
  };

  const getRecordsSearchRequestForeignFilters = (searchRequest): Map<String, Filter> => {
    let filters = new Map<String, Filter[]>();

    for (let key in searchRequest) {
      if (typeof searchFieldNameVsFieldMap[key] == "undefined" || searchRequest[key] == null || searchRequest[key] === "") {
        continue;
      }
      if (!searchFieldNameVsFieldMap[key].fEntityType) {
        //handled by separate function, to clean up and combine
        continue;
      }
      if (searchFieldNameVsFieldMap[key].type === "text") {
        filters.set(searchFieldNameVsFieldMap[key].fEntityType, [
          ...(filters.get(searchFieldNameVsFieldMap[key].fEntityType) || []),
          {
            field: key,
            values: [searchRequest[key]],
            type: "CONTAINS",
          },
        ]);
      } else if (searchFieldNameVsFieldMap[key].type === "number") {
        filters.set(searchFieldNameVsFieldMap[key].fEntityType, [
          ...(filters.get(searchFieldNameVsFieldMap[key].fEntityType) || []),
          {
            field: key,
            values: [searchRequest[key]],
            type: "EQUALS",
          },
        ]);
      } else {
        filters.set(searchFieldNameVsFieldMap[key].fEntityType, [
          ...(filters.get(searchFieldNameVsFieldMap[key].fEntityType) || []),
          {
            field: key,
            values: [searchRequest[key]],
            type: "IN",
          },
        ]);
      }
    }

    let combinedFilters = new Map<String, Filter>();
    for (let [fEntityType, entityFilters] of Array.from(filters)) {
      combinedFilters.set(fEntityType, { filters: entityFilters, type: "AND" });
    }
    return combinedFilters;
  };

  //TODO: THIS TO BE MOVED TO OUT TO DIFF CLASSES
  const getEntitySpecificFilters = (detailRecordType) => {
    if (recordType === "gate-pass" && detailRecordType === "material-outward-dtl") {
      return {
        type: "EQUALS",
        field: "gpFlg",
        values: true,
      };
    }
    return {};
  };

  populateSearchFieldsNameToFieldMap(searchFields);
  return loading ? (
    <Box sx={{ ml: 22, display: "flex" }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Box sx={{ marginLeft: 0, marginTop: 2, width: "82vw" }}>
        <form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Stack
              sx={{ mb: 1, alignItems: "center" }}
              direction="row"
              // alignItems="center"
              spacing={3}
            >
              <h4 className="sub-header"> {pageTitle} </h4>
              <Stack
                sx={{ mb: 1, alignItems: "center" }}
                direction="row"
                // alignItems="center"
                spacing={1}
              >
                {creationAllowed && (
                  <Button
                    sx={{
                      width: "80px",
                      height: "40px",
                      fontSize: "0.8rem",
                    }}
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    component={Link}
                    to={`/${recordType}/add/`}
                  >
                    New
                  </Button>
                )}
                <ExcelReport
                  fechData={getExcelReportData}
                  disabled={recordTotalCount === 0}
                  fileName={pageTitle}
                  title={recordType === "stock" ? "Summary" : hasDetailEntity(recordType) ? "Master" : null}
                />
                {(hasDetailEntity(recordType) || recordType === "stock") && (
                  <ExcelReport
                    fechData={getExcelReportDataDtl}
                    disabled={recordTotalCount === 0}
                    fileName={pageTitle + " Details"}
                    // color="secondary"
                    title="Details"
                    setError={setError}
                  />
                )}
              </Stack>
            </Stack>
          </div>

          {isTransactionEntity(recordType) && (
            <Box sx={{ ml: 1, mb: 0, height: "4ch" }}>
              <DatePicker
                name="from"
                label="From"
                minDate={getOpeningStockDate()}
                value={startDate}
                maxDate={endDate}
                sx={{
                  mt: 0,
                  mr: 2,
                  width: "17ch",
                  "& .MuiInputBase-input": {
                    fontSize: "smaller",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "smaller",
                  },
                }}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={(date) => {
                  handleStartDateChange(date);
                }}
              />
              <DatePicker
                name="to"
                label="To"
                minDate={startDate}
                value={endDate}
                maxDate={dayjs(new Date())}
                sx={{
                  mt: 0,
                  mr: 2,
                  width: "17ch",
                  "& .MuiInputBase-input": {
                    fontSize: "smaller",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "smaller",
                  },
                }}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={(date) => {
                  handleEndDateChange(date);
                }}
              />
            </Box>
          )}
          <Box sx={{ mt: 1, mb: 3, height: "4ch" }}>
            <Stack sx={{ p: 1 }} direction="row" alignItems="top" spacing={1}>
              {searchFields.map((searchField) => getJsxForField(searchField))}
              <IconButton aria-label="reset" color="success" onClick={handleClearInput}>
                <RefreshIcon />
              </IconButton>
              {isTransactionEntity(recordType) && (
                <Button color="secondary" endIcon={<SwapVertIcon />} onClick={handleSortToggle}>
                  {sortOrder}
                </Button>
              )}
            </Stack>
          </Box>
          {/* <Divider sx={{ mb: 2, width: "100%" }} /> */}
        </form>
      </Box>
      <Box sx={{ marginTop: 0 }}>
        {error && (
          <div
            style={{
              width: "80rem",
              justifyContent: "center",
              marginTop: "1rem",
            }}
            className="alert alert-danger"
          >
            {error}
          </div>
        )}
        <Stack direction="row" alignItems="centertop">
          <RecordTable
            records={filteredRecords}
            handleRecordSelection={handleRecordSelection}
            recordsTotalCount={recordTotalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={pageNo}
            rowsPerPage={rowsPerPage}
            columns={columns}
            tableContainerHeight={isTransactionEntity(recordType) ? "66vh" : "76vh"}
          />
        </Stack>
      </Box>
    </>
  );

  function handleSortToggle() {
    let newSortOrder = "DESC";
    setSortOrder((prevSortOrder) => {
      newSortOrder = prevSortOrder === "ASC" ? "DESC" : "ASC";
      return newSortOrder;
    });
    fetchRecordsForFilters({ idSortOrder: newSortOrder });
  }

  function getJsxForField(searchField) {
    let width = searchField.props && searchField.props.widthFactor ? 20 * searchField.props.widthFactor : 20;
    if (searchField.type === "text") {
      return (
        <TextField
          key={searchField.name}
          id={searchField.name}
          name={searchField.name}
          label={searchField.label}
          size="small"
          onChange={handleRecordSearchRequestChange}
          onInput={(e) => toInputUppercase(e, searchField)}
          type="text"
          sx={{
            mb: 10,
            width: `${width}ch`,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          value={recordSearchRequest && recordSearchRequest[searchField.name] ? recordSearchRequest[searchField.name] : ""}
        />
      );
    } else if (searchField.type === "number") {
      return (
        <TextField
          key={searchField.name}
          id={searchField.name}
          name={searchField.name}
          label={searchField.label}
          size="small"
          onChange={handleRecordSearchRequestChange}
          type="number"
          sx={{
            // mb: 10,
            width: `${width}ch`,
            "& .MuiInputBase-input": {
              fontSize: "smaller",
            },
            "& .MuiInputLabel-root": {
              fontSize: "smaller",
            },
          }}
          value={recordSearchRequest && recordSearchRequest[searchField.name] ? recordSearchRequest[searchField.name] : ""}
        />
      );
    } else if (searchField.type === "checkbox") {
      return (
        <FormGroup key={searchField.name}>
          <FormControlLabel
            required
            control={
              <Switch
                key={searchField.name}
                checked={recordSearchRequest ? recordSearchRequest[searchField.name] : false}
                name={searchField.name}
                onChange={handleRecordSearchRequestChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={searchField.label}
          />
        </FormGroup>
      );
    } else if (searchField.type === "multiPicklist") {
      return (
        <FormControl
          sx={{
            mb: 1,
            width: `${width}ch`,
            fontSize: "1rem",
          }}
          size="small"
          variant="outlined"
          key={searchField.name}
        >
          <InputLabel id={`select-${searchField.name}`}>{searchField.label}</InputLabel>
          <Select
            labelId={`select-${searchField.name}`}
            id={`select-${searchField.name}`}
            multiple
            value={recordSearchRequest && Array.isArray(recordSearchRequest) ? recordSearchRequest[searchField.name] : []}
            name={searchField.name}
            onChange={handlePicklistValueChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  "& .MuiInputBase-input": {
                    fontSize: "smaller",
                  },
                }}
              >
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {searchField.values.map((typeOption) => {
              return (
                <MenuItem key={typeOption.value} value={typeOption.value}>
                  <Checkbox
                    checked={
                      recordSearchRequest &&
                      recordSearchRequest[searchField.name] &&
                      recordSearchRequest[searchField.name].indexOf(typeOption.value) > -1
                    }
                  />
                  <ListItemText primary={typeOption.label} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    } else if (searchField.type === "picklist") {
      return (
        <FormControl key={searchField.name} sx={{ width: `${width}ch` }}>
          <InputLabel
            id={searchField.name}
            sx={{
              fontSize: "smaller",
              lineHeight: "1.5", // Adjust to match text alignment
              marginTop: !(recordSearchRequest && !isEmptyObj(recordSearchRequest[searchField.name])) ? "-7px" : null,
            }}
          >
            {searchField.label}{" "}
          </InputLabel>
          <Select
            sx={{
              mb: 1,
              padding: "2px 2px",
              "& .MuiInputBase-input": {
                fontSize: "smaller",
                padding: "5px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "smaller",
              },
            }}
            size="small"
            labelId={searchField.name}
            id={searchField.name}
            label={searchField.label}
            name={searchField.name}
            required={!searchField.allowNull}
            value={recordSearchRequest && !isEmptyObj(recordSearchRequest[searchField.name]) ? recordSearchRequest[searchField.name] : ""}
            disabled={searchField.displayOnly}
            onChange={handleRecordSearchRequestChange}
          >
            <MenuItem value="" disabled>
              {searchField.label}
            </MenuItem>
            {searchField.values.map((valueObject) => (
              <MenuItem key={valueObject.value} value={valueObject.value}>
                {valueObject.label}
              </MenuItem>
            ))}
            {recordSearchRequest && !isEmptyObj(recordSearchRequest[searchField.name]) && (
              <MenuItem value="" sx={{ backgroundColor: "lightgray" }}>
                Clear Selection
              </MenuItem>
            )}
          </Select>
        </FormControl>
      );
    } else {
      return <></>;
    }
  }
}

export default withAuth(RecordTabularView);
