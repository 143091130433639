import { formatDate } from "../services/dateUtils";
import { outTransactionTypeValues } from "./materialOutward";
import { Field } from "../types/field";
import { materialTypeValues, senderDocTypeValues, transportModeValues } from "../standardEntities/materialInwardFields";
import { projectTypeValues } from "../standardEntities/project";
import { translatePicklistValue } from "../services/fieldsUtils";

export const receiverDocTypeValues = [
  { value: "PO", label: "Purchase Order" },
  { value: "LO", label: "Lease Order" },
  { value: "MR", label: "Material Request" },
  { value: "CO", label: "Child Work Order" },
  { value: "NA", label: "N/A" },
];

export const gatePassDetailFields: Field[] = [
  {
    name: "sno",
    label: "SNo.",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 3,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 2,
    },
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
    autoFilled: true,
  },
  // {
  //   name: "partSerialNo",
  //   label: "Part Serial",
  //   type: "text",
  //   allowNull: true,
  //   autoFilled: true,
  // },
  // {
  //   name: "batchNo",
  //   label: "Batch No",
  //   type: "text",
  //   allowNull: true,
  //   autoFilled: true,
  // },
  {
    name: "totalQuantity",
    label: "Out Quantity",
    type: "text",
    autoFilled: true,
  },
  {
    name: "rate",
    label: "Rate",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 1.4,
    },
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
];

export const gatePassFields: Field[] = [
  {
    name: "sectionA",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorName",
                label: "Sender Locator Name",
                props: {
                  width: "17vw",
                },
                type: "lookup",
                lookupType: "locator",
                lookupFieldName: "locatorCode",
                lookupCategory: "gpDocLocator",
                lookupParams: [
                  {
                    key: "projectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "locatorType",
                      values: ["PS", "CS"],
                    },
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docLocatorCode",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyCode",
                    fieldName: "docCompanyCode",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyName",
                    fieldName: "docCompanyName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "docStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationCode",
                    fieldName: "docStationCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "docAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "docAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "docGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "todayDate",
                    fieldName: "docDate",
                  },
                ],
                linkedFields: [
                  {
                    name: "moaId",
                    value: "",
                  },
                ],
              },
              {
                name: "docLocatorCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "moaId",
                label: "MOA No",
                type: "lookup",
                lookupType: "material-outward",
                lookupFieldName: "moaId",
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "MISSING",
                  field: "docLocatorCode",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      field: "docLocatorCode",
                      type: "EQUALS",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "id",
                      resolveValue: true,
                      valueFieldName: "moaId",
                    },
                    {
                      field: "docDate",
                      type: "LTE",
                      resolveValue: true,
                      isGlobalConstant: true,
                      valueFieldName: "docDate",
                    },
                  ],
                },
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "projectCode",
                    fieldName: "projectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "projectName",
                    fieldName: "projectName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "transactionType",
                    fieldName: "transactionType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "withinState",
                    fieldName: "withinState",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "materialType",
                    fieldName: "materialType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "docStateCode",
                    fieldName: "docStateCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "docLocatorType",
                    fieldName: "docLocatorType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "projectType",
                    fieldName: "projectType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "virtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverProjectCode",
                    fieldName: "receiverProjectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverProject",
                    fieldName: "receiverProject",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "docContractorCode",
                    fieldName: "docContractorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "docContractorName",
                    fieldName: "docContractorName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverContractorCode",
                    fieldName: "receiverContractorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverContractorName",
                    fieldName: "receiverContractorName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "totalValue",
                    fieldName: "totalValue",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverLocatorCode",
                    fieldName: "receiverLocatorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverLocatorName",
                    fieldName: "receiverLocatorName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverProjectCode",
                    fieldName: "receiverProjectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverProjectName",
                    fieldName: "receiverProjectName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverProjectType",
                    fieldName: "receiverProjectType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverMaterialType",
                    fieldName: "receiverMaterialType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverCompanyCode",
                    fieldName: "receiverCompanyCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverCompanyName",
                    fieldName: "receiverCompanyName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "details",
                    fieldName: "details",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverStationName",
                    fieldName: "receiverStationName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverStateName",
                    fieldName: "receiverStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverStationCode",
                    fieldName: "receiverStationCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverVirtualLocator",
                    fieldName: "receiverVirtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverAddress1",
                    fieldName: "receiverAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "receiverAddress2",
                    fieldName: "receiverAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "autoInward",
                    fieldName: "autoInward",
                  },
                  {
                    lookupFieldName: "id",
                    fieldName: "moaDate",
                  },
                ],
              },
              {
                name: "moaDate",
                label: "MOA Date",
                type: "date",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "docCompanyName",
                label: "Sender Company Name",
                props: {
                  width: "17vw",
                },
                type: "text",
                displayOnly: true,
              },
              {
                name: "docCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docContractorName",
                label: "Contractor Name",
                type: "text",
                autoFilled: true,
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "docContractorCode",
                label: "Contractor",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectName",
                label: "Sender Project Name",
                type: "text",
                autoFilled: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "projectCode",
                label: "Sender Project",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docStationName",
                label: "Sender Station",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "docGSTNo",
                label: "Sender GST No",
                type: "text",
                autoFilled: true,
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "sections",
                type: "formSection",
                childFields: [
                  {
                    name: "materialType",
                    label: "Sender Type",
                    type: "picklist",
                    defaultValue: "",
                    values: materialTypeValues,
                    autoFilled: true,
                    props: {
                      width: "10vw",
                    },
                  },
                  {
                    name: "transactionType",
                    label: "Transaction Type",
                    type: "picklist",
                    defaultValue: "",
                    autoFilled: true,
                    values: outTransactionTypeValues,
                    props: {
                      width: "10vw",
                    },
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "virtualLocator",
                label: "Sender Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS","CS","PS"],
            },
            childFields: [
              {
                name: "receiverVirtualLocator",
                label: "Receiver Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          // {
          //   name: "sections",
          //   type: "formSection",
          //   childFields: [
          //     {
          //       name: "totalValue",
          //       label: "Total Value",
          //       type: "number",
          //       autoFilled: true,
          //       props: {
          //         width: "10vw",
          //       },
          //     },
          //     {
          //       name: "totalPkgs",
          //       label: "Total Packages",
          //       type: "number",
          //       props: {
          //         width: "10vw",
          //       },
          //     },
          //   ],
          // },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "id",
                label: "GP No",
                type: "text",
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "docDate",
                label: "GP Date",
                type: "date",
                displayOnly: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "withinState",
                label: "Within State",
                type: "checkbox",
                allowUpdate: false,
                displayOnly: true,
              },
              {
                name: "autoInward",
                label: "Auto Inward",
                type: "checkbox",
                allowUpdate: true,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "cancelled",
                      values: [true],
                    },
                    //We disable marking as delivered for internal shift or store transfers as they are marked automatically
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["PS", "CS"],
                    },
                  ],
                },
                visibility: {
                  type: "IN",
                  field: "transactionType",
                  values: ["PS", "CS", "IS"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverCompanyName",
                label: "Receiver Company Name",
                type: "text",
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "receiverCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "receiverLocatorCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "receiverLocatorName",
                label: "Receiver Locator Name",
                autoFilled: true,
                type: "text",
                props: {
                  width: "17vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverContractorName",
                label: "Contractor Name",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "17vw",
                },
              },
              {
                name: "receiverContractorCode",
                label: "Contractor",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                allowNull: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS","CS"],
            },
            childFields: [
              {
                name: "receiverProjectType",
                label: "Receiver Project Type",
                type: "picklist",
                values: projectTypeValues,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "receiverMaterialType",
                label: "Receiver Material Type",
                type: "picklist",
                autoFilled: true,
                values: materialTypeValues,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS","CS"],
            },
            childFields: [
              {
                name: "receiverProjectName",
                label: "Receiver Project Name",
                type: "lookup",
                lookupType: "project",
                // lookupCategory: "receiverProject",
                autoFilled: true,
                // lookupFilter: {
                //   type: "AND",
                //   filters: [
                //     {
                //       type: "EQUALS",
                //       field: "projectCode",
                //       resolveValue: true,
                //       valueFieldName: "receiverProjectCode",
                //     },
                //   ],
                // },
                
                lookupParams: [
                  // {
                  //   key: "docStateCode",
                  //   resolveValue: true,
                  //   valueFieldName: "docStateCode",
                  // },
                  // {
                  //   key: "receiverContractorCode",
                  //   resolveValue: true,
                  //   valueFieldName: "receiverContractorCode",
                  // },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "receiverProjectCode",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "receiverProjectType",
                  },
                ],
                props: {
                  width: "17vw",
                },
              },
              {
                name: "receiverProjectCode",
                label: "Receiver Project Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS","CS"],
            },
            childFields: [
              {
                name: "receiverAddress1",
                label: "Address1 ",
                type: "text",
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS","CS"],
            },
            childFields: [
              {
                name: "receiverAddress2",
                label: "Address2 ",
                type: "text",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "receiverStationName",
                label: "Receiver Station",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "receiverStateName",
                label: "Receiver State/Country",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "details",
    label: "Details",
    type: "formTable",
    autoFilled: true,
    tableFields: gatePassDetailFields,
    props: {
      width: "82vw",
    },
  },
  {
    name: "sectionB",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "transportMode",
                label: "Transport Mode",
                type: "picklist",
                defaultValue: "TP",
                props: {
                  width: "12vw",
                },
                values: [
                  { value: "TP", label: "Transporter" },
                  { value: "CR", label: "Courier" },
                  { value: "SF", label: "Self" },
                ],
              },
              {
                name: "vehicleNo",
                label: "Vehicle Number",
                type: "text",
                allowNull: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "vendorName",
                label: "Vendor Name",
                type: "lookup",
                lookupType: "company",
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "vendorCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "vendorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "ownerFlg",
                      values: false,
                    },
                    {
                      type: "EQUALS",
                      field: "serviceFlg",
                      values: true,
                    },
                  ],
                },
                defaultValue: null,
                allowUpdate: true,
                props: {
                  width: "17vw",
                },
                disability: {
                  field: "transportMode",
                  type: "IN",
                  values: ["SF"],
                },
              },
              {
                name: "vendorCode",
                label: "Code",
                type: "text",
                defaultValue: null,
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "consignmentNote",
                label: "Consignment Note",
                type: "text",
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  field: "transportMode",
                  type: "IN",
                  values: ["SF"],
                },
              },
              {
                name: "ewayBillNo",
                label: "Eway Bill No",
                type: "text",
                allowNull: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "totalPkgs",
                label: "Total Packages",
                type: "number",
                props: {
                  width: "10vw",
                },
              },
              // {
              //   name: "expectedDate",
              //   label: "Expected Delivery",
              //   type: "date",
              //   allowNull: true,
              //   allowUpdate: true,
              //   constraints: [{ type: "DATE_RANGE", afterNow: true }],
              //   disability: {
              //     type: "IN",
              //     field: "delivered",
              //     values: [true],
              //   },
              //   props: {
              //     width: "10vw",
              //   },
              // },
              {
                name: "deliveryDate",
                label: "Actual Delivery Date",
                type: "date",
                allowNull: false,
                allowUpdate: true,
                constraints: [
                  {
                    type: "DATE_RANGE",
                    beforeNow: true,
                    minDateScript: {
                      valueFields: ["docDate"],
                    },
                  },
                ],
                props: {
                  width: "10vw",
                },
                disability: {
                  type: "NIN",
                  field: "delivered",
                  values: [true],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverPerson",
                label: "Receiver Person",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
                disability: {
                  type: "NIN",
                  field: "delivered",
                  values: [true],
                },
                visibility: {
                  type: "NIN",
                  field: "transactionType",
                  values: ["IS", "CS", "PS"],
                },
              },
              {
                name: "receiverPerson",
                label: "Receiver Doc Type",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
                disability: {
                  type: "NIN",
                  field: "delivered",
                  values: [true],
                },
                visibility: {
                  type: "IN",
                  field: "transactionType",
                  values: ["IS", "CS", "PS"],
                },
              },
              {
                name: "receiverMobile",
                label: "Receiver Mobile",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
                disability: {
                  type: "NIN",
                  field: "delivered",
                  values: [true],
                },
                visibility: {
                  type: "NIN",
                  field: "transactionType",
                  values: ["IS", "CS", "PS"],
                },
              },
              {
                name: "receiverMobile",
                label: "Receiver MIR No",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
                disability: {
                  type: "NIN",
                  field: "delivered",
                  values: [true],
                },
                visibility: {
                  type: "IN",
                  field: "transactionType",
                  values: ["IS", "CS", "PS"],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "cancelled",
                label: "Cancelled",
                type: "checkbox",
                defaultValue: false,
                allowUpdate: true,
                disabledForCreation: true,
                disability: {
                  type: "IN",
                  field: "delivered",
                  values: [true],
                },
                linkedFields: [
                  {
                    name: "autoInward",
                    value: false,
                  },
                ],
              },
              {
                name: "delivered",
                label: "Delivered",
                type: "checkbox",
                defaultValue: false,
                allowUpdate: true,
                disabledForCreation: true,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "cancelled",
                      values: [true],
                    },
                    //We disable marking as delivered for internal shift or store transfers as they are marked automatically
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["PS", "CS", "IS"],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "remarks",
                label: "Remarks",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                props: {
                  width: "17vw",
                  spacing: "0.75vw",
                },
              },
              {
                name: "totalValue",
                label: "Total Value",
                type: "number",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "fileInfos",
            label: "File Infos",
            type: "attachment",
            allowUpdate: true,
            allowNull: true,
          },
        ],
      },
    ],
  },
];

export const gatePassColumns = [
  {
    name: "id",
    label: "GP No",
    type: "text",
  },
  {
    name: "docDate",
    label: "GP Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
    displayOnly: true,
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "transactionType",
    label: "Transaction",
    allowNull: true,
    type: "text",
    format: (value) => {
      const transactionType = outTransactionTypeValues.find((item) => item.value === value);
      return transactionType ? transactionType.label : "Unknown";
    },
  },
  {
    name: "materialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => {
      const materialType = materialTypeValues.find((item) => item.value === value);
      return materialType ? materialType.label : "Unknown";
    },
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "delivered",
    label: "Delivered",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const gatePassSearchColumns = [
  {
    name: "gpId",
    label: "GP No",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docDate",
    label: "GP Date",
    type: "date",
    defaultValue: null,
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
    defaultValue: null,
  },
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "transactionType",
    label: "Transaction Type",
    type: "picklist",
    defaultValue: null,
    values: outTransactionTypeValues,
  },
  // {
  //   name: "materialType",
  //   label: "Material Type",
  //   type: "picklist",
  //   defaultValue: null,
  //   values: materialTypeValues,
  // },
  {
    name: "companyName",
    label: "Receiver Company",
    fEntityType: "receiverCompany",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Receiver Locator",
    fEntityType: "receiverLocator",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "delivered",
    label: "Delivered",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];

export const gatePassExcelColumns = [
  {
    name: "id",
    label: "GP No",
    type: "text",
  },
  {
    name: "docDate",
    label: "GP Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Out Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "Out State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
  },
  {
    name: "moaDate",
    label: "MOA Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docCompanyCode",
    label: "Out Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => outTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverLocatorCode",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator Name",
    type: "text",
  },
  {
    name: "receiverAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "receiverAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "receiverStationName",
    label: "Receiver Station",
    type: "text",
  },
  {
    name: "receiverStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "receiverCompanyCode",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPkgs",
    label: "Total Packages",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "transportMode",
    label: "Transport Mode",
    type: "text",
    format: (value) => transportModeValues.find((item) => item.value === value).label,
  },
  {
    name: "vendorCode",
    label: "Code",
    type: "text",
  },
  {
    name: "vendorName",
    label: "Vendor Name",
    type: "text",
  },
  {
    name: "consignmentNote",
    label: "Consignment Note",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  // {
  //   name: "expectedDate",
  //   label: "Expected Delivery",
  //   type: "date",
  //   format: (value) => formatDate(value),
  // },
  {
    name: "delivered",
    label: "Delivered",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "deliveryDate",
    label: "Actual Delivery Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "receiverPerson",
    label: "Receiver Person",
    type: "text",
  },
  {
    name: "receiverMobile",
    label: "Receiver Mobile",
    type: "text",
  },
];

export const gatePassDetailExcelColumns = [
  {
    name: "gpId",
    label: "GP No",
    type: "text",
  },
  {
    name: "gpDate",
    label: "GP Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Out Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Out Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "Out State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "moaId",
    label: "MOA No",
    type: "text",
  },
  {
    name: "moaDate",
    label: "MOA Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docCompanyCode",
    label: "Out Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => outTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverLocatorCode",
    label: "Receiver Locator",
    type: "text",
  },
  {
    name: "receiverLocatorName",
    label: "Receiver Locator Name",
    type: "text",
  },
  {
    name: "receiverAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "receiverAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "receiverStationName",
    label: "Receiver Station",
    type: "text",
  },
  {
    name: "receiverStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "receiverCompanyCode",
    label: "Receiver Company",
    type: "text",
  },
  {
    name: "receiverCompanyName",
    label: "Receiver Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR Number",
    type: "text",
  },
  // {
  //   name: "partSerialNo",
  //   label: "Part Serial",
  //   type: "text",
  // },
  // {
  //   name: "batchNo",
  //   label: "Batch No",
  //   type: "text",
  // },
  {
    name: "totalQuantity",
    label: "Out Quantity",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "rate",
    label: "Rate",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "delivered",
    label: "Delivered",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];
