//todo: move all this to specific handlers
import { nullSafeString } from "./stringUtils";
import { formatDate } from "./dateUtils";
import {
  materialTypeValues,
  inTransactionTypeValues,
  receiverDocTypeValues,
  transportModeValues,
  senderDocTypeValues,
} from "../standardEntities/materialInwardFields";

import { locatorTypeValues } from "../standardEntities/locator";
import { projectTypeValues } from "../standardEntities/project";
import { workTypeValues } from "../standardEntities/cbSheetFields";
import { translatePicklistValue } from "./fieldsUtils";
import { outTransactionTypeValues } from "../standardEntities/materialOutward";
import { Field } from "../types/field";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import _ from "lodash";

const options = {
  margin: { top: 40 },
  styles: {
    fontSize: 12,
    font: "Arial",
    lineWidth: 0.75,
  },
  columnStyles: {
    0: { fontStyle: "underline", textColor: [0, 0, 0] },
  },
  bodyStyles: { valign: "top" },
  columnWidth: "auto",
  theme: "plain",
  tableLineColor: [128, 128, 128],
};

export const generatePDFContent = (fields: Field[], record, recordType) => {
  let title = [[""]];
  let senderReceiverData = [[""]];
  let dtHead = [];
  let details = [];
  let invoiceValues = [[""]];
  let remarks = [[""]];
  let otherValues = [[""]];
  if (recordType === "material-inward") {
    title = [["MATERIAL INWARD RECEIPT"]];
    senderReceiverData = [
      [
        `MIR No: ${record.id} \nMIR Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, inTransactionTypeValues)}\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)} \nDoc Date: ${formatDate(record.receiverDocDate)} `,
        `Receiver: ${record.docCompanyName} \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nContractor: ${
          record.docContractorName
        } (${record.docContractorCode}) \nStation: ${record.docStationName} (${record.docStateName}) \t\tGST No: ${nullSafeString(
          record.docGSTNo
        )} \nLocator: ${record.docLocatorName} (${record.docLocatorCode}) \t\tLocator Type: ${translatePicklistValue(
          record.docLocatorType,
          locatorTypeValues
        )} \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `Total Packages: ${record.totalPkgs} \nInvoice Value: ${record.totalValue} \nInventory Value: ${
          record.inventoryValue
        }  \nSender Doc: ${translatePicklistValue(record.senderDocType, senderDocTypeValues)} \nDoc No: ${nullSafeString(
          record.senderDocNo
        )} \nDoc Date: ${formatDate(record.senderDocDate)} \nSender Material: ${translatePicklistValue(
          nullSafeString(record.senderMaterialType ?? record.materialType),
          materialTypeValues
        )}`,
        `Sender: ${record.senderCompanyName} \n${record.senderAddress1} \n${nullSafeString(record.senderAddress2)} \nContractor: ${nullSafeString(
          record.senderContractorName
        )} (${nullSafeString(record.senderContractorCode)}) \nStation: ${record.senderStationName} (${
          record.senderStateName
        }) \t\tGST No: ${nullSafeString(record.senderGSTNo)} \nLocator: ${record.senderLocatorName} (${
          record.senderLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.senderLocatorType, locatorTypeValues)} \nProject: ${nullSafeString(
          record.senderProjectName
        )} (${nullSafeString(record.senderProjectCode)}) \t\tVirtual Locator: ${nullSafeString(record.senderVirtualLocator)} `,
      ],
    ];
    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "Recvd Qty", "Basic Value"]];
    details = record.details.map((row, index) => {
      return [index + 1, row.itemName, row.itemCustCode, row.itemId, row.uom, row.totalQuantity, row.basicAmount];
    });
    let totalInwardQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);
    details.push(["", "SubTotal", "", "", "", totalInwardQuantity.toFixed(1), record.basicValue.toFixed(2)]);
    invoiceValues = [
      [
        `IGST Value:\nCGST Value:\nSGST Value:\nFreight Value:`,
        `${record.iGSTValue.toFixed(2)}\n${record.cGSTValue.toFixed(2)}\n${record.sGSTValue.toFixed(2)}\n${record.freightValue.toFixed(2)}`,
      ],
      [`Total Value:`, `${record.totalValue.toFixed(2)}`],
    ];
    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];
    otherValues = [
      [
        `Gate Entry No: ${nullSafeString(record.gateEntryNo)} \nGate Entry Date: ${formatDate(record.gateEntryDate)} \nVehicle No: ${nullSafeString(
          record.vehicleNo
        )}\t\t\t\t\t\t\t\t\t\t`,
        `Transport Mode: ${translatePicklistValue(record.transportMode, transportModeValues)} \nTransport Vendor: ${
          record.vendorName
        } (${nullSafeString(record.vendorCode)}) \nConsignment Note: ${nullSafeString(record.consignmentNote)}\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "Submit Pending"} \nEway Bill: ${nullSafeString(
          record.ewayBillNo
        )}\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: invoiceValues, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "material-outward") {
    title = [["MATERIAL OUTWARD AUTHORIZATION"]];
    senderReceiverData = [
      [
        `MOA No: ${record.id} \nMOA Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, outTransactionTypeValues)}\nSender Doc: ${translatePicklistValue(
          record.senderDocType,
          senderDocTypeValues
        )}\nDoc No: ${nullSafeString(record.senderDocNo)} \nDoc Date: ${formatDate(record.senderDocDate)} `,
        `Sender: ${record.docCompanyName} \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nContractor: ${
          record.docContractorName
        } (${record.docContractorCode}) \nStation: ${record.docStationName} (${record.docStateName}) \t\tGST No: ${nullSafeString(
          record.docGSTNo
        )} \nLocator: ${record.docLocatorName} (${record.docLocatorCode}) \t\tLocator Type: ${translatePicklistValue(
          record.docLocatorType,
          locatorTypeValues
        )} \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `Total Value: ${record.totalValue.toFixed(2)} \n\n\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)} \nDoc Date: ${formatDate(
          record.receiverDocDate
        )} \nReceiver Material: ${translatePicklistValue(nullSafeString(record.receiverMaterialType ?? record.materialType), materialTypeValues)}`,
        `Receiver: ${record.receiverCompanyName} \n${record.receiverAddress1} \n${nullSafeString(
          record.receiverAddress2
        )} \nContractor: ${nullSafeString(record.receiverContractorName)} (${nullSafeString(record.receiverContractorCode)}) \nStation: ${
          record.receiverStationName
        } (${record.receiverStateName}) \t\tGST No: ${nullSafeString(record.receiverGSTNo)} \nLocator: ${record.receiverLocatorName} (${
          record.receiverLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.receiverLocatorType, locatorTypeValues)} \nProject: ${nullSafeString(
          record.receiverProjectName
        )} (${nullSafeString(record.receiverProjectCode)}) \t\tVirtual Locator: ${nullSafeString(record.receiverVirtualLocator)} `,
      ],
    ];

    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "MIR No", "Row No", "Out Qty", "Value"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemName,
        row.itemCustCode,
        row.itemId,
        row.uom,
        row.mirId,
        row.mirRowId,
        row.totalQuantity.toFixed(1),
        row.totalAmount.toFixed(2),
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    details.push(["", "Totals", "", "", "", "", "", totalDetailQuantity.toFixed(1), record.totalValue.toFixed(2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];
    otherValues = [
      [
        `Request By: ${nullSafeString(record.requestBy)} \nRequest Ref No: ${nullSafeString(
          record.requestRefNo
        )} \t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "In Process"}\nGate Pass Made: ${
          record.gpFlg ? "Yes" : "No"
        }\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Gate Pass No: ${nullSafeString(record.gpId)} \nGate Pass Date: ${formatDate(record.gpDate)}\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "cb-sheet") {
    title = [["CONSUMPTION BOOK SHEET"]];
    senderReceiverData = [
      [
        `CBS No: ${record.id} \nCS Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\n\nProject Type: ${translatePicklistValue(record.projectType, projectTypeValues)}`,
        `Contractor: ${record.contractorName} (${record.contractorCode}) \nLocator: ${record.docLocatorName} (${record.docLocatorCode})\nSender: ${
          record.docCompanyName
        } (${record.docCompanyCode}) \t\tGST No: ${nullSafeString(record.docGSTNo)}  \nStation: ${record.docStationName} \t\tState: ${
          record.docStateName
        }\nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${record.virtualLocator}`,
      ],
      [
        `${record.projectType === "FTTH" ? `CWO Id:` : `Job Id:`} ${record.projectType === "FTTH" ? record.cwoId : record.jobId} \n\n${
          record.projectType === "FTTH" ? `CWO Date:` : `PO No:`
        } ${record.projectType === "FTTH" ? formatDate(record.cwoDate) : record.poNo}`,
        `${record.projectType === "FTTH" ? `CWO No:` : `Site Id:`} ${record.projectType === "FTTH" ? record.cwoNo : record.siteId}\n${
          (record.projectType === "FTTH") === null ? `CWO Type:` : `Job Type:`
        } ${translatePicklistValue(record.projectType === "FTTH" ? record.cwoType : record.jobType, workTypeValues)}  \t\t${
          record.projectType === "FTTH" ? `Work Area:` : ``
        } ${record.projectType === "FTTH" ? record.workArea : ``}\nRoute Length: ${record.routeLength} \t\t${
          record.projectType === "FTTH" ? `Home Pass:` : ``
        } ${record.projectType === "FTTH" ? record.homePass : ``}`,
      ],
    ];

    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "Out Qty", "Value"]];

    details = record.details.map((row, index) => {
      return [index + 1, row.itemName, row.itemCustCode, row.itemId, row.uom, row.totalQuantity.toFixed(1), row.totalAmount.toFixed(2)];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    let totalDetailAmount = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalAmount);
    }, 0);

    details.push(["", "Totals", "", "", "", totalDetailQuantity.toFixed(1), totalDetailAmount.toFixed(2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    otherValues = [
      [
        `Invoice/MB No: ${nullSafeString(record.invoiceMBNo)} \nInvoice/MB Date: ${
          record.closureId === null ? `` : formatDate(record.invoiceMBDate)
        }\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Closure No: ${nullSafeString(record.closureId)} \nClosure Date: ${
          record.closureId === null ? `` : formatDate(record.closureDate)
        }\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
        `Status: ${record.submit ? "Submitted" : record.cancelled ? "Cancelled" : "Not Submitted"}\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: remarks, halign: "left" },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  } else if (recordType === "gate-pass") {
    title = [["GATE PASS"]];
    senderReceiverData = [
      [
        `Gate Pass No: ${record.id} \nGate Pass Date: ${formatDate(record.docDate)} \nMaterial Type: ${translatePicklistValue(
          record.materialType,
          materialTypeValues
        )}\nTrans Type: ${translatePicklistValue(record.transactionType, outTransactionTypeValues)}\nSender Doc: ${translatePicklistValue(
          record.senderDocType,
          senderDocTypeValues
        )}\nDoc No: ${nullSafeString(record.senderDocNo)} \nDoc Date: ${formatDate(record.senderDocDate)} `,
        `Sender: ${record.docCompanyName} \n${record.docAddress1} \n${nullSafeString(record.docAddress2)} \nContractor: ${nullSafeString(
          record.docContractorName
        )} (${nullSafeString(record.docContractorCode)}) \nStation: ${record.docStationName} (${record.docStateName}) \t\tGST No: ${nullSafeString(
          record.docGSTNo
        )} \nLocator: ${record.docLocatorName} (${record.docLocatorCode}) \t\tLocator Type: ${translatePicklistValue(
          record.docLocatorType,
          locatorTypeValues
        )} \nProject: ${record.projectName} (${record.projectCode}) \t\tVirtual Locator: ${nullSafeString(record.virtualLocator)}`,
      ],
      [
        `MOA No: ${record.moaId} \nMOA Date: ${formatDate(record.moaDate)}, \n\nRecv Doc: ${translatePicklistValue(
          record.receiverDocType,
          receiverDocTypeValues
        )} \nDoc No: ${nullSafeString(record.receiverDocNo)}\nDoc Date: ${formatDate(
          record.receiverDocDate
        )} \nReceiver Material: ${translatePicklistValue(nullSafeString(record.receiverMaterialType ?? record.materialType), materialTypeValues)}`,
        `Receiver: ${record.receiverCompanyName} \n${record.receiverAddress1} \n${nullSafeString(
          record.receiverAddress2
        )} \nContractor: ${nullSafeString(record.receiverContractorName)} (${nullSafeString(record.receiverContractorCode)}) \nStation: ${
          record.receiverStationName
        } (${record.receiverStateName}) \t\tGST No: ${nullSafeString(record.receiverGSTNo)} \nLocator: ${record.receiverLocatorName} (${
          record.receiverLocatorCode
        }) \t\tLocator Type: ${translatePicklistValue(record.receiverLocatorType, locatorTypeValues)} \nProject: ${nullSafeString(
          record.receiverProjectName
        )} (${nullSafeString(record.receiverProjectCode)}) \t\tVirtual Locator: ${nullSafeString(record.receiverVirtualLocator)}  `,
      ],
    ];

    dtHead = [["SN", "Description Of Material", "Part Code", "Item Id", "UOM", "MIR No", "Row No", "Out Qty", "Value"]];

    details = record.details.map((row, index) => {
      return [
        index + 1,
        row.itemName,
        row.itemCustCode,
        row.itemId,
        row.uom,
        row.mirId,
        row.mirRowId,
        row.totalQuantity.toFixed(1),
        row.totalAmount.toFixed(2),
      ];
    });

    let totalDetailQuantity = record.details.reduce((total, detail) => {
      return total + parseFloat(detail.totalQuantity);
    }, 0);

    details.push(["", "Totals", "", "", "", "", "", totalDetailQuantity.toFixed(1), record.totalValue.toFixed(2)]);

    remarks = [[`Remarks: ${nullSafeString(record.remarks)}`]];

    otherValues = [
      [
        `Total Value: ${record.totalValue.toFixed(2)} \nTotal Packages: ${record.totalPkgs} \nStatus: ${
          record.cancelled ? "Cancelled" : record.delivered ? "Delivered" : "Delivery Pending"
        }\t\t\t\t\t\t\t\t\t\t`,
        `Transport Mode: ${translatePicklistValue(record.transportMode, transportModeValues)} \nTransport Vendor: ${
          record.vendorName
        } (${nullSafeString(record.vendorCode)}) \nVehicle No: ${nullSafeString(record.vehicleNo)}\t\t\t\t\t\t\t\t\t\t`,
        `Consignment Note: ${nullSafeString(record.consignmentNote)}\nEway Bill: ${nullSafeString(record.ewayBillNo)}\t\t\t\t\t\t\t\t\t\t`,
      ],
      [
        `Delivery Date: ${formatDate(record.deliveryDate)}\t\t\t\t\t\t\t\t\t\t`,
        `Receiver Person: ${nullSafeString(record.receiverPerson)}\t\t\t\t\t\t\t\t\t\t`,
        `Receiver Mobile: ${nullSafeString(record.receiverMobile)}\t\t\t\t\t\t\t\t\t\t`,
      ],
    ];

    return {
      rows: [
        { headers: title, halign: "center" },
        { body: senderReceiverData },
        { headers: dtHead, body: details, halign: "right" },
        { body: remarks },
        { body: otherValues },
      ],
      footer: "ONLY FOR INTERNAL USE",
    };
  }

  return {};
};

export const generatePDF = (generateContent: () => any, sameTab = false) => {
  let content = generateContent();
  // Create a new jsPDF instance
  let pdf: any = new jsPDF({
    orientation: "landscape", // Set orientation to portrait
    unit: "pt", // Use pixels as units
    format: "a4", // Set page format to A4
  });

  if (!_.isEmpty(content.rows)) {
    content.rows.forEach((row, index) => {
      pdf.autoTable({
        head: row.headers,
        startY: index === 0 ? 50 : pdf.lastAutoTable.finalY,
        body: row.body,
        ...options,
        didParseCell: function (data) {
          data.cell.styles.valign = "top"; // Vertical alignment
          data.cell.styles.halign = row.halign ? row.halign : "left"; // Horizontal alignment
        },
      });
    });
  }
  // Add the table to the PDF

  pdf.internal.scaleFactor = 1;

  const pageCount = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    let pageSize = pdf.internal.pageSize;
    pdf.setPage(i);
    pdf.setFontSize(10);
    pdf.text(`Page ${i} of ${pageCount}`, pageSize.getWidth() - 100, pageSize.getHeight() - 10);
    if (content.footer) {
      const textWidth = (pdf.getStringUnitWidth(content.footer) * pdf.internal.getFontSize()) / pdf.internal.scaleFactor;
      const textX = (pageSize.getWidth() - textWidth) / 2;
      pdf.text(content.footer, textX, pageSize.getHeight() - 10);
    }
  }
  if (sameTab) {
    const pdfDataUri2 = pdf.output("bloburl");
    window.location.href = pdfDataUri2;
  } else {
    const pdfDataUri = pdf.output("datauristring");
    // Open the PDF in a new tab
    const newTab = window.open();
    newTab.document.write('<iframe width="100%" height="100%" src="' + pdfDataUri + '"></iframe>');
  }
};
